import { sendLogs } from "./awsCWnotifier"

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = date => {
    const today = new Date()
    return (
        /* eslint-disable operator-linebreak */
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
        /* eslint-enable */
    )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
    if (!value) return value
    return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
    const date = new Date(value)
    let formatting = { month: 'short', day: 'numeric' }

    if (toTimeForCurrentDay && isToday(date)) {
        formatting = { hour: 'numeric', minute: 'numeric' }
    }

    return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const getUserData = () => JSON.parse(localStorage.getItem('persist:mozrest'))
export const isUserLoggedIn = () => {
    const user = getUserData()
    return localStorage.getItem('fb-onboarding-mozrest:token') && !!user && user.user.length > 0
}

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
    if (userRole === 'admin') return '/'
    if (userRole === 'client') return '/access-control'
    return '/login'
}

export const getProviderLogo = () => {
    const provider = JSON.parse(localStorage.getItem("fb-onboarding-mozrest:provider"))
    return provider.logo
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary25: '#7367f01a', // for option hover bg-color
        primary: '#7367f0', // for selected option bg-color
        neutral10: '#7367f0', // for tags bg-color
        neutral20: '#ededed', // for input border-color
        neutral30: '#ededed' // for input hover border-color
    }
})

export const clearCookies = () => {
    localStorage.removeItem('meta_onboarding:redirect_url')
    localStorage.removeItem('fb-onboarding-mozrest:token')
    localStorage.removeItem('fb-onboarding-mozrest:userData')
    localStorage.removeItem('fb-onboarding-mozrest:refreshtoken')
    localStorage.removeItem('persist:mozrest')
    localStorage.removeItem('fb-onboarding-mozrest:companyType')
    localStorage.removeItem('fb-onboarding-mozrest:totalVenues')
    localStorage.removeItem('fb-onboarding-mozrest:totalVenues')
    localStorage.removeItem('fb-onboarding-provider:key')
    localStorage.removeItem('fb-onboarding-provider:id')
}

export const setupFBExtras = (type, data, businessId) => {
    const mzId = type === 'custom' ? businessId : data.id
    console.log("mzId", mzId)
    console.log("type", type)
    console.log("businessId", businessId)
    return {
        setup: {
            external_business_id: mzId,
            timezone: data.timezone || 'Europe/Paris',
            currency: data.currency || 'EUR',
            business_vertical: 'RESERVATIONS'
        },
        business_config: {
            business: {
                name: data.name
            },
            page_cta: {
                enabled: true,
                cta_button_text: 'Reserve',
                cta_button_url: `${process.env.REACT_APP_WIDGET_URL}?key=${process.env.REACT_APP_FB_WIDGET_KEY}&mzId=${mzId}&display=${type}`,
                below_button_text: 'In partnership with Mozrest'
            },
            ig_cta: {
                enabled: true,
                cta_button_text: 'Reserve',
                cta_button_url: `${process.env.REACT_APP_WIDGET_URL}?key=${process.env.REACT_APP_IG_WIDGET_KEY}&mzId=${mzId}&display=${type}`
            }
        },
        repeat: false
    }
}

export const buildRedirectPath = () => {

    const redirect_url = localStorage.getItem('meta_onboarding:redirect_url')
    const url = new URL(redirect_url)

    const domainName = url.hostname
    const queryParams = Array.from(url.searchParams.entries()).reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {})

    if (domainName === 'www.instagram.com' && queryParams.session_id && queryParams.gql_uuid) {
        return `${process.env.REACT_APP_ONBOARDING_URL}/ig/${queryParams.session_id}/${queryParams.gql_uuid}/callback`
    } else if (domainName === 'www.facebook.com' && queryParams.page_id && queryParams.gql_uuid) {
        return `${process.env.REACT_APP_ONBOARDING_URL}/fb/${queryParams.page_id}/${queryParams.gql_uuid}/callback`
    } else {
        return redirect_url
    }
}

export const buildFixPath = (origin, id, uuid) => {

    const redirect_url = localStorage.getItem('meta_onboarding:redirect_url')

    if (origin === 'ig') {
        return `${process.env.REACT_APP_IG_REDIRECT_URL}&session_id=${id}&gql_uuid=${uuid}`
    } else if (origin === 'fb') {
        return `${process.env.REACT_APP_FB_REDIRECT_URL}&page_id=${id}&gql_uuid=${uuid}`
    } else {
        return redirect_url
    }
}

export const FBConnect = (type, data, businessId, fixPath = null) => {
    const extras = setupFBExtras(type, data, businessId)
    const redirect_url = fixPath ? fixPath : localStorage.getItem('meta_onboarding:redirect_url')

    sendLogs(`SSOCallbackContainerfixPath: ${fixPath}`)
    sendLogs(`SSOCallbackContainerLocalStoragePath: ${localStorage.getItem('meta_onboarding:redirect_url')}`)
    sendLogs(`SSOCallbackContainer: redirect_url: ${redirect_url}&extras=${encodeURIComponent(JSON.stringify(extras))}`)

    clearCookies()

    window.location.replace(
        `${redirect_url}&extras=${encodeURIComponent(JSON.stringify(extras))}`
    )
}
