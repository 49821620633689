import AWS from 'aws-sdk'

AWS.config.update({
    region: 'eu-west-1',
    accessKeyId: process.env.REACT_APP_AWS_CW_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_CW_SECRET_KEY
})

const cloudWatch = new AWS.CloudWatchLogs({ apiVersion: '2014-03-28' })

// Nombre del grupo de registros y flujo de registros
const logGroupName = 'meta-onboarding'
const logStreamName = process.env.REACT_APP_LOG_STREAM_NAME

export const sendLogs = (logMessage) => {
    const params = {
        logGroupName,
        logStreamName,
        logEvents: [
          {
            message: logMessage,
            timestamp: new Date().getTime()
          }
        ]
      }
    
      cloudWatch.putLogEvents(params, function (err, data) {
        if (err) {
          console.error('Error al enviar registro: ', err, err.stack)
        }
      })
}